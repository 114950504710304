// header

$(window).on('load resize', function(){
  var minW = $(window).width();
  var maxW = 767;
  if (minW <= maxW) {

    $(function () {
          $(this).removeClass('open');
          $(".js-nav").removeClass('open');
          $(".js-btn").removeClass('open');
      });

  } else {

    $(function () {
      $(this).removeClass('open');
      $(".js-nav").removeClass('open');
      $(".js-btn").removeClass('open');
  });

  }
});


$(function () {
  $(".js-btn").click(function () {
      $(this).toggleClass('open');
      $(".js-nav").toggleClass('open');
  });
  });